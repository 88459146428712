import React, {useEffect} from 'react';
import { ongService } from '../../services/ong.services';

export const Donations = () => {
  useEffect(() => {
    setTimeout(() => {
      getInitialData();
    }, 300);
  }, []);
  const getInitialData = async () => {
    const ongData = await ongService.getData();
    console.log(ongData)
    if(ongData.mercadopago) {
      const script = document.createElement('script')
      const test = document.createElement('div');
      test.innerHTML = ongData.mercadopago;
      const tagToGet =    test.firstElementChild; 
      if(tagToGet && tagToGet.getAttribute('data-preference-id')) {
        script['src'] = tagToGet.getAttribute('src');
        script.setAttribute('data-preference-id', tagToGet.getAttribute('data-preference-id'))
        console.log( script)
        script.setAttribute('data-source', tagToGet.getAttribute('data-source'))

        console.log(script)
        document.querySelector('#scripted').appendChild(script)


      }

 
    }
       
     
      }

  return (
    <div className="blog-grid full-width">
      <div id="wrapper">
        <main id="main" className="site-main">
          <div className="page-title background-blog">
            <div className="container">
              <h1>Donar</h1>
              <div className="breadcrumbs">
                <ul>
                  <li>
                    <a href="#">
                      Inicio
                    </a>
                    <span>/</span>
                  </li>
                  <li> Donar </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 main-content">

                <h4 style={{letterSpacing: 'initial'}}>

                  Las donaciones son procesadas en el entorno seguro de MercadoPago. Haga click en el siguiente botón para comenzar:

                </h4>
              
              <div id="scripted">
              </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
