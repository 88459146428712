import React from 'react';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import swal from 'sweetalert';
import { Link, useHistory } from 'react-router-dom';
import './login.css';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../redux/actions/userActions';

// Yup is a 3rd party validator, we first define the initial values (what's the schema) and then,
// for each one we stablish its type (.mail for example) .required ("text to be displayed") and .min length.

export const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  //Defines what to use in the input fields, grabs props and places them as input's type and name (i.e.: email, text).
  // MyTextInput is defined on the return() of the function where it requires label, name, type and password.
  const MyTextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <input className="field" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    );
  };

  const onSubmit = async (values) => {
    try {
      const data = JSON.stringify(values, null, 2);
      const body = JSON.parse(data);
      await dispatch(loginUser(body));
      history.push('/');
    } catch (error) {
      swal('Ooops!', 'There was an error', 'error');
    }
  };

  return (
    <div className="form-login">
      <h2>Ingresa con tu cuenta</h2>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Dirección de email inválida')
            .required('Campo requerido'),
          password: Yup.string()
            .min(6, 'La contraseña debe ser de al menos 6 caracteres')
            .required('Campo requerido')
        })}
        onSubmit={onSubmit}
      >
        <Form className="clearfix">
          <MyTextInput
            label="Dirección email"
            name="email"
            type="email"
            placeholder="micorreo@email.com"
          />
          <MyTextInput
            label="Contraseña"
            name="password"
            type="password"
            placeholder="Contraseña"
          />
          <div className="inline clearfix">
            <button type="submit" className="btn-primary">
              Login
            </button>
            <p>
              ¿No eres miembro?{' '}
              <Link to="/signup" aria-hidden="true">
                Regístrate Ahora
              </Link>
            </p>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
