import React, { useEffect, useState } from 'react';

// components
import { CardList } from './Card/CardList';
import { CarouselSlides } from './Slides/Slides';
import { ongService } from '../../services/ong.services';

export const Home = () => {
  //State that holds home's fetched data
  const [home, setHome] = useState();
  const [homeDescription, setHomeDescription] = useState()

  //Helper that fetches data
  const getData = () => {
    //This will use an API to fetch data. Meanwhile we use 'res' object
    const res = {
      title: 'Fortalecer sin Fronteras'
    };

    //Sets home state
    setHome(res.title);
  };

  //useEffect runs getData only one time  after Home is properly mounted.


  useEffect(() => {
    ongService
      .getDescription()
      .then((res) => {
        setHome(res.name)
        setHomeDescription(res.short_description);
      })
      .catch((err) => {
        setHome('Fortalecer sin fronteras')

        setHomeDescription('Le damos la bienvenida a nuestro sitio');
      });
  }, []);

  return (
    <div className="mb-5">
      <CarouselSlides />

      <a
        href="https://api.whatsapp.com/send?phone=0111558757704&text=Hola!"
        className="wapp-float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp wapp-my-float"></i>
      </a>

      <h2 className="title mt-5 mb-3"> {home} </h2>
      <p className="site-description">
        {homeDescription}
      </p>
      <CardList />
    </div>
  );
};
