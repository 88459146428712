import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { string, object } from 'yup';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { signupUser } from '../../redux/actions/userActions';
import { useDispatch } from 'react-redux';

const SignupSchema = object().shape({
  firstName: string().required('Completa tu nombre, por favor.'),
  lastName: string().required('Completa tu apellido, por favor.'),
  email: string()
    .email('El correo ingresado no es válido.')
    .required('Es necesario un email para registrarte.'),
  password: string()
    .required('Necesitas una contraseña para registrarte.')
    .min(6, 'La contraseña es demasiado corta.')
});

export const Signup = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // declare a new variable of state to redirect

  const submitHandle = async (values) => {
    try {
      const data = JSON.stringify(values, null, 2);
      const body = JSON.parse(data);
      await dispatch(signupUser(body));
      history.push('/');
    } catch (error) {
      swal('Ooops!', 'There was an error', 'error');
    }
  };

  return (
    <div className="container my-5">
      <div className="main-content">
        <div className="form-login form-register">
          <h2>Crear una cuenta</h2>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              password: ''
            }}
            validationSchema={SignupSchema}
            onSubmit={submitHandle}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="field">
                  <Field name="firstName" placeholder="Nombre" />
                  <ErrorMessage name="firstName" component="div" />
                </div>
                <div className="field">
                  <Field name="lastName" placeholder="Apellido" />
                  <ErrorMessage name="lastName" component="div" />
                </div>
                <div className="field">
                  <Field type="email" name="email" placeholder="Email" />
                  <ErrorMessage name="email" component="div" />
                </div>
                <div className="field">
                  <Field
                    type="password"
                    name="password"
                    placeholder="Contraseña"
                  />
                  <ErrorMessage name="password" component="div" />
                </div>
                <div className="inline clearfix">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn-primary"
                  >
                    Registrarme
                  </button>
                  <p>
                    ¿Ya formas parte? <Link to="/login">Ingresa</Link>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
