import React from 'react';
import { Link, useHistory } from 'react-router-dom';

export const NewsItem = ({ id, title, date, handleEdit, handleDelete }) => {
  const editItem = (e) => {
    handleEdit(id);
  };

  const deleteItem = (e) => {
    handleDelete(id);
  };

  return (
    title && (
      <tr>
        <td>{id}</td>
        <td>{title}</td>
        <td>{date}</td>
        <td >
          <Link to={`/edit/news/${id}`} >
            <span className="ion-edit pointer"></span>
          </Link>        </td>
        <td onClick={deleteItem}>
          <span className="ion-android-delete pointer"></span>
        </td>
      </tr>
    )
  );
};
