import React from 'react';
import { NewDetailsTitle } from './NewDetailsTitle/NewDetailsTitle';
import { NewDetailsBody } from './NewDetailsBody/NewDetailsBody';
//import NewDetailsFooter from './new-details-footer/New-details-footer';
import { NewDetailsImage } from './NewDetailsImage/NewDetailsImage';
//import NewDetailsAuthor from './new-details-author/New-details-author';
import { NewDetailsDate } from './NewDetailsDate/NewDetailsDate';
import { NewDetailsCategory } from './NewDetailsCategory/NewDetailsCategory';
//import NewDetailsCategoryWidget from './new-details-category-widget/New-details-category-widget';
//import NewDetailsRecentsWidget from './new-details-recents-widget/New-details-recents-widget';
import { newsService } from '../../services/news.services';
import BASE_URL from '../../services/base.service';

export const NewDetails = () => {
  // declare a new variable of state, wich we will call "user"
  // "user" containr the information of the user
  const [news, setNews] = React.useState([]);

  // receives as a parameter a function that will be executed every time our component is rendered
  // in this case it received "getData"
  React.useEffect(() => {
    getData();
  }, []);

  // the function "getData" is setting the data
  const getData = () => {
    // call the service and use a method "getAll" to return all the users
    let pathNewsId = window.location.pathname.toString();
    let newsId = pathNewsId.substring(pathNewsId.lastIndexOf('/') + 1);

    newsService
      .get(newsId)
      .then((news) => {
        // setting the data of the user
        setNews(news);
      })
      .catch((err) => {
        // if there are an Error, we show it on console
        console.log('Error inesperado', err);
      });
  };

  const renderImage = (imageToCheck) => {
    if(imageToCheck) {
      if(imageToCheck[0] == '/') {
        return BASE_URL + imageToCheck
      }
      return imageToCheck
    }
  }


  return (
    <div id="wrapper">
      <main id="main" className="site-main">
        <NewDetailsTitle id={news.id} title={news.title} />

        <div className="container">
          <div className="row">
            <div className="col-lg-8 main-content mt-3">
              <article className="post">
                <div className="entry-content">
                  <NewDetailsImage id={news.id} image={renderImage(news.image)} />
                  <ul className="post-meta">
                
                     
                  </ul>
                  <NewDetailsBody id={news.id} content={news.content} />
                </div>
              </article>
            </div>
            {/* <div className="col-lg-4 sidebar">
              <NewDetailsCategoryWidget props={news}/> 
              <NewDetailsRecentsWidget/>
            </div> */}
          </div>
        </div>
      </main>
    </div>
  );
};
