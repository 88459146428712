import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({
  children,
  fieldToValidate = 'loggedIn',
  ...rest
}) => {
  const user = useSelector((state) => state.user);

  return (
    <Route
      render={() =>
        user[fieldToValidate] === true ? children : <Redirect to="/login" />
      }
      {...rest}
    />
  );
};
