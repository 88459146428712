import API from './config.services';

// create an Object to send the URI to access
const USER_ENDPOINT = {
  LIST_USER: '/users', // URI to call all the users
  USER_DATA: '/users/', //URI to get the user data by id
  LOGIN: '/authentication/login', //URI to login
  REGISTER: '/register', //URI to register
  EDIT_USER: '/users/' //URI to update user

  // in the future there will be more URIs
};

// create a Object wich contain two functions type promise
// when we call the API this return async data
const userService = {
  // function to return the list of users
  getAll: () =>
    new Promise((resolve, reject) => {
      API.get(USER_ENDPOINT.LIST_USER)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  // all of this functions is going to made in a future when we have the true URL

  // function to return an user by id
  get: (id) =>
    new Promise((resolve, reject) => {
      API.get(USER_ENDPOINT.USER_DATA + id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  // function to return if an user was authenticated
  postLogin: (data) =>
    new Promise((resolve, reject) => {
      API.post(USER_ENDPOINT.LOGIN, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  // function to return if an user was registered
  postRegister: (data) =>
    new Promise((resolve, reject) => {
      console.log(data);
      API.post(USER_ENDPOINT.REGISTER, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  // function to return if an user was edited
  update: (data, id) =>
    new Promise((resolve, reject) => {
      API.patch(USER_ENDPOINT.EDIT_USER + id, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  // function to return if an user was deleted
  delete: (id) =>
    new Promise((resolve, reject) => {
      API.delete(USER_ENDPOINT.USER_DATA + id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })
  };


// export the userService to use in the other files
export default userService;
