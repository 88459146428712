import { USER_LOGGEDIN, USER_LOGGEDOUT, USER_EDIT } from './types';
import userService from '../../services/users.services';
import jwt_decode from 'jwt-decode';

//? Auxiliar fn for persisting state
const saveStorage = ({ user }) => (localStorage.user = JSON.stringify(user));

export const signupUser = (values) => async (dispatch, getState) => {
  try {
    const { token } = await userService.postRegister(values);
    const { user } = jwt_decode(token);
    if (user.roleId === 1) user.isAdmin = true;
    dispatch({
      type: USER_LOGGEDIN,
      payload: { ...user, token }
    });
    saveStorage(getState());
  } catch (error) {
    throw error;
  }
};

export const loginUser = (values) => async (dispatch, getState) => {
  try {
    const { token } = await userService.postLogin(values);
    const { user } = jwt_decode(token);
    if (user.roleId === 1) user.isAdmin = true;
    dispatch({
      type: USER_LOGGEDIN,
      payload: { ...user, loggedIn: true, token }
    });
    saveStorage(getState());
  } catch (error) {
    throw error;
  }
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('user');
  dispatch({ type: USER_LOGGEDOUT });
};

export const editUser = (values, id) => async (dispatch, getState) => {
  id = getState().user.id;
  const { token } = getState();

  try {
    const user = await userService.update(values, id);
    dispatch({
      type: USER_EDIT,
      payload: { ...user, token }
    });
  } catch (error) {
    throw error;
  }
};
