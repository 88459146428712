import axios from 'axios'; // import the library AXIOS

// connection with URL of our API
// in this case is a mock with "jsonplaceholder" (it's momentary)

// create a const with API's address
const BASE_URL = 'https://ongs.alkemy.org/fortalecer/';
//const BASE_URL = 'http://localhost:4000/';
export {BASE_URL}
// exports a const that containt the API's URL
// with method create we can instance of axios
const API = axios.create({
  baseURL: BASE_URL
});
// Set the AUTH token for any request
API.interceptors.request.use(function (config) {
  const user = localStorage.getItem('user');
  if(user) {
    const token = (JSON.parse(user)).token;
    config.headers.Authorization = token ? `Bearer ${token}` : '';

  }
  return config;
});

// export the API to use in the other files
export default API;
