import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

export const CustomLink = ({ exact, to, children }) => {
  const match = useRouteMatch({ exact, path: to });
  return (
    <li className={match ? 'active' : ''}>
      <Link to={to}>{children}</Link>
    </li>
  );
};
