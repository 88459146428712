import React from 'react';

// css
import './App.css';

// route
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// components
import { Home } from './components/Home/Home';
import { Entries } from './components/Entries/Entries';
import { Footer } from './components/Footer/Footer';
import { Account } from './components/Account/Account';
import { PrivateRoute } from './components/Router/PrivateRoute';
import { Header } from './components/Header/Header';
import { About } from './components/About/About';
import { Contact } from './components/Contact/Contact';
import { Users } from './components/Users/Users';
import { Profile } from './components/Profile/Profile';
import { Donations } from './components/Donations/Donations';
import { Login } from './components/Login/login';
import { Signup } from './components/Signup/SignupForm';
import { NewDetails } from './components/NewDetails/NewDetails';
import { EditProfile } from './components/Account/editProfile';
import { EntriesForm } from './components/Account/EntriesForm';
import { ParticipantsEditionForm } from './components/Account/ParticipantsEditionForm';

import { useSelector } from 'react-redux';
import ScrollToTop from './ScrollToTop';

export const App = () => {
  const user = useSelector((state) => state.user);


  return (
    <div>
      <Router>
        {/* commponent Header */}
        <Header />

        <ScrollToTop> 

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <PrivateRoute path="/account">
            <Account />
          </PrivateRoute>
          <PrivateRoute
            path={['/create/article', '/create/news']}
            user={user}
            fieldToValidate="isAdmin"
          >
            <EntriesForm />
          </PrivateRoute>
          <PrivateRoute
            path={['/edit/article/:id', '/edit/news/:id']}
            user={user}
            fieldToValidate="isAdmin"
          >
            <EntriesForm />
          </PrivateRoute>
          <PrivateRoute
            path={['/create/participant', '/edit/participant/:id']}
            user={user}
            fieldToValidate="isAdmin"
          >
            <ParticipantsEditionForm />
          </PrivateRoute>
          <Route path="/nosotras">
            <About />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/contacto">
            <Contact />
          </Route>
          <Route path={['/articulos', '/novedades']} component={Entries} />
          <Route path="/donations">
            <Donations />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/news/:id">
            <NewDetails />
          </Route>
          <Route path="/articulo/:id">
            <NewDetails />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path={`/editProfile/:id`} exact>
            <EditProfile />
          </Route>
        </Switch>
        </ScrollToTop>
        {/* Component Footer */}
        <Footer />
      </Router>
    </div>
  );
};
