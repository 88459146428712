import React from 'react';
import { BrowserRouter as Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

export const Card = ({ title, content, image, date, id }) => {

    // declare a new variable of state to redirect
    const [redirect, setRedirect] = React.useState([]);

    // function to redirect to "Home"
    const renderRedirect = (id) => {
      if (redirect === true) {
        return <Redirect to={'/news/' + id} />;
      }
    };
  
    const onClick = () => {
      setRedirect(true);
    };

    
  //Card relies on a parent component to fetch his data (CardList.js).
  //If reactive behavior inside Card component is needed,  states holding title,description,date could be created
  //e.g. ---> const [title, setTitle] = useState('Default title')
  return (
    <>
          {renderRedirect(id)}

    <div className="col-lg-4 mb-3">
      <article className="post">
        <div className="blognews-thumb">
          <a className="overlay" href="#" onClick={onClick}>
            <img
              className="fit"
              src={image}
              alt="imagen"
              style={{ width: '630px', height: '380px' }}
            />{' '}
            {/* measuring image size */}
            <span className="ion-ios-search-strong"></span>
          </a>
        </div>
        <div className="blognews-info">

          <h3 className="">
          <Link to={`/news/${id}`}>

          {title}
          </Link>          </h3>


     
          <div className="">
            <p className="mb-2 text-secondary">
              {content.length <= 100
                ? content
                : content.substring(100, -1) + '...'}
            </p>
            <button className="btn btn-info" onClick={onClick}>
              Ver Más
            </button>
            {/* Create this ternary to display only one hundred characters and in the case 'if not' show one hundred characters together with '...' to not show more */}
          </div>
        </div>
      </article>
    </div>
    </>
  );
};
