import API from './config.services';

// create an Object to send the URI to access
const ONG_ENDPOINT = {
  BASE: '/ongs',
  DATA: '/ongs/data', // URI to get the basic data
  DESCRIPTION: '/ongs/desc', //URI to get the descriptions
  LINKS: '/ongs/links'
};

export const ongService = {
  // function to return the basic data of the ong
  getData: () =>
    new Promise((resolve, reject) => {
      API.get(ONG_ENDPOINT.DATA)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  // function to return a news by id
  getDescription: () =>
    new Promise((resolve, reject) => {
      API.get(ONG_ENDPOINT.DESCRIPTION)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  getLinks: () =>
    new Promise((resolve, reject) => {
      API.get(ONG_ENDPOINT.LINKS)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

    put: (data) =>
    new Promise((resolve, reject) => {
      console.log(data);
      API.put(ONG_ENDPOINT.BASE, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })
};
