import API from './config.services';

// create an Object to send the URI to access
const ARTICLE_ENDPOINT = '/articles';

// create a Object wich contain functions type promise
// when we call the API this return async data
export const articleService = {
  // function to return the list of articles
  getAll: () =>
    new Promise((resolve, reject) => {
      API.get(ARTICLE_ENDPOINT)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  // function to return a article by id
  get: (id) =>
    new Promise((resolve, reject) => {
      API.get(ARTICLE_ENDPOINT + id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  // function to post new article data
  post: (data) =>
    new Promise((resolve, reject) => {
      console.log(data);
      API.post(ARTICLE_ENDPOINT, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

    put: (data, id) =>
    new Promise((resolve, reject) => {
      console.log(data);
      API.put(ARTICLE_ENDPOINT + '/' + id, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })

};
