import { USER_LOGGEDIN, USER_LOGGEDOUT } from '../actions/types';

let initialState = JSON.parse(localStorage.getItem('user'));

if (!initialState) initialState = { loggedIn: false };

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGGEDIN:
      return {
        ...state,
        loggedIn: true,
        token: action.payload.token,
        ...action.payload
      };
    case USER_LOGGEDOUT:
      return { loggedIn: false };
    default:
      return state;
  }
};
