import React, { useState,  useEffect } from "react";
import { useLocation, Redirect } from 'react-router-dom';
import { participantsService } from '../../services/participants.services';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { string, object, mixed} from 'yup';
import swal from 'sweetalert';
import { BASE_URL } from "../../services/config.services";

const createImgValidatorObject = (name) => ({
  name: name,
  message: 'Formato no soportado. Elija una imagen .jpg, .jpeg, .png o .gif.',
  test: (value) =>
    value &&
    ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(value.type)
});

const validateText = () => string().required('Complete el campo.');

const validateImage = (name) =>
  mixed().required('Suba una imagen.').test(createImgValidatorObject(name));

const entrySchema = object().shape({
  title: validateText(),
  image: validateImage('image')
});



export const ParticipantsEditionForm = () => {

  const location = useLocation();
  const [type, setType] = useState('');
  // declare a new variable of state to redirect
  const [redirect, setRedirect] = useState([]);
  const [formContent, setFormContent] = useState('');
  const [err, setErr] = useState(false);
  const [url, setUrl] = useState('');
  const [visibility, setVisibility] = useState(false);
  const [ckState, setCKState] = useState('');
  const [participantId, setParticipantId] = useState('');


  
  useEffect(() => {
    let splitted = location.pathname.split('/')[1];
    let formType = splitted === 'create' ? 'Crear' : 'Editar';
    setType(formType);
  }, []);

  useEffect(() => {
    if( location.pathname.split('/')[1] === 'edit') {
      let id = location.pathname.split('/')[3];
      setParticipantId(id)
      participantsService
        .get(id)
        .then(data => {
          if(data.image) {
            setUrl(BASE_URL + data.image)
            setVisibility(true);

          }
          setFormContent(data)});
    };
  }, []);

  // function to redirect to "Home"
  const renderRedirect = () => {
    if (redirect === true) {
      return <Redirect to="/account/participants" />;
    }
  };

  const EditParticipantSchema = object().shape({
    name: string().required(
      'Completa con el nombre del participante, por favor.'
    ),
    text: string(),
    image: string(),
    facebookUrl: string(),
    linkedinUrl: string(),
    instagramUrl: string()
  });


  const handleChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setUrl(event.target.result);
      setVisibility(true);
      setFieldValue(`image`, file);
    };
  };
  const handleRemove = (e, values) => {
    e.preventDefault();
    values.image = '';
    setUrl('');
    setVisibility(false);
  };

  
  const getData = () => formContent ? formContent :  {
    name: '',
    text: '',
    image: '',
    facebookUrl: '',
    linkedinUrl: '',
    instagramUrl: ''
    }




  // function to handle the submit, sends the data to API to register a new user
  const submitHandle = (values, { setSubmitting }) => {
    if (type === 'Crear') {
      setTimeout(() => {
        console.log(values)
        const data = JSON.stringify(values, null, 2);
        const body = JSON.parse(data);
        setSubmitting(false);
        // Call the service and use postParticipant
        participantsService
          .postParticipant(body)
          .then((result) => {
            // set redirect to "true"
            setRedirect(true);
          })
          .catch((err) => {
            // if there is and error, set redirect to "false" and show a sweet alert
            setRedirect(false);
            swal('Ooops!', 'There was an error', 'error');
          });
      }, 400);
    } else if (type ==='Editar'){
      console.log(values)
      let formData = new FormData();

      for (let prop in values) {
        formData.append(prop, values[prop]);
      }
      participantsService
      .updateParticipant(participantId, formData)
      .then((result) => {
        // set redirect to "true"
        setRedirect(true);
      })
      .catch((err) => {
        // if there is and error, set redirect to "false" and show a sweet alert
        setRedirect(false);
        swal('Ooops!', 'There was an error', 'error');
      })
      console.log('Editar');
    };

  };

  return (
    <div className="container">
      <div className="main-content">
        <div className="form-login">
        {renderRedirect()}
          <Formik
          initialValues={getData()}
          enableReinitialize={true}

          
            validationSchema={EditParticipantSchema}
            onSubmit={submitHandle}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <h4>{`${type} un participante`}</h4>
                <div className="field">
                  <Field name="name" placeholder="Nombre del participante" />
                  <ErrorMessage name="name" component="div" />
                </div>
                <div className="field">
                  <Field name="text" placeholder="Descripcion del participante" />
                  <ErrorMessage name="text" component="div" />
                </div>


                <div className="upload-bg">
                  <label
                    htmlFor="upload"
                    style={{ display: 'block', width: '100%' }}
                  >
                    <div id="boxchoice">
                      <div
                        className="choicefile"
                        style={
                          visibility === false
                            ? { display: 'block' }
                            : { display: 'none' }
                        }
                      >
                        <i className="fa fa-cloud-upload" aria-hidden="true" />
                        {'  '}Suba su imagen
                      </div>
                      <p />
                    </div>
                  </label>
                  <Field
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="upload"
                    name="uploadfile"
                    onChange={(e) => handleChange(e, setFieldValue)}
                  />

                  {values[`image`] !== '' && (
                    <div id="thumbbox">
                      <img
                        style={
                          visibility === true
                            ? { display: 'block' }
                            : { display: 'none' }
                        }
                        src={url}
                        alt="selection"
                        id="myimage"
                      />
                      <button
                        className="removeimg"
                        onClick={(e) => handleRemove(e, values)}
                      ></button>
                    </div>
                  )}

                  <ErrorMessage name="image" component="div" />
                  <label className="filename" />
                </div>

               
                <div className="field">
                  <Field name="facebookUrl" placeholder="Facebook del participante" />
                  <ErrorMessage name="facebookUrl" component="div" />
                </div>
                <div className="field">
                  <Field name="linkedinUrl" placeholder="Linkedin del participante" />
                  <ErrorMessage name="linkedinUrl" component="div" />
                </div>
                <div className="field">
                  <Field name="instagramUrl" placeholder="Instagram del participante" />
                  <ErrorMessage name="instagramUrl" component="div" />
                </div>

                <div className="inline clearfix">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn-primary"
                  >
                    {type=== 'Crear' ? 'Crear nuevo participante' : 'Enviar cambios'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
