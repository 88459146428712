import React from 'react';
import { useSelector } from 'react-redux';

import { Route, useRouteMatch, Link } from 'react-router-dom';
import { Profile } from './Profile';
import { Dashboard } from './Dashboard';
import { NewsList } from './NewsList';
import { UserList } from './UserList';
import { ArticlesList } from './ArticlesList';
import { ParticipantsList } from './ParticipantsList';

import { CustomLink } from '../Router/CustomLink';
import { PrivateRoute } from '../Router/PrivateRoute';
import { MercadoPago } from './MercadoPago';

export const Account = ({ ...rest }) => {
  const { url } = useRouteMatch();
  const user = useSelector((state) => state.user);

  //? Example data, should come from props

  return (
    <div className="account-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <nav className="account-bar">
              <ul>
                <CustomLink to={`${url}/dashboard`}>Escritorio</CustomLink>
                <CustomLink to={`${url}/profile`}>Perfil</CustomLink>
             
                {user.isAdmin && (
                  <CustomLink to={`${url}/novedades`}>
                    Prensa
                  </CustomLink>
                )}
                {user.isAdmin && (
                  <CustomLink to={`${url}/articles`}>
                    Publicaciones
                  </CustomLink>
                )}
                {user.isAdmin && (
                  <CustomLink to={`${url}/participants`}>
                    Participantes ONG
                  </CustomLink>
                )}
                 {user.isAdmin && (
                  <CustomLink to={`${url}/mercadopago`}>
                    MercadoPago
                  </CustomLink>
                )}
                   {user.isAdmin && (
                  <CustomLink to={`${url}/userlist`}>
                    Usuarios
                  </CustomLink>
                )}
                
              </ul>
            </nav>
          </div>
          <div className="col-lg-9">
            <Route path={`${url}/dashboard`}>
              <Dashboard />
            </Route>
            <Route path={`${url}/profile`}>
              <Profile />
            </Route>
            
            <PrivateRoute path={`${url}/novedades`} fieldToValidate="isAdmin">
            <section className="py-4 d-flex flex-column">
                <button className="btn-primary mx-auto mb-3">
                  <Link to="/create/news">Crear Novedad</Link>
                </button>
                <NewsList type="Novedades" />
              </section>

            </PrivateRoute>

            
            <PrivateRoute path={`${url}/userlist`} fieldToValidate="isAdmin">
              <UserList />
            </PrivateRoute>

            <PrivateRoute path={`${url}/mercadopago`} fieldToValidate="isAdmin">
              <MercadoPago />
            </PrivateRoute>

            <PrivateRoute
              path={`${url}/articles`}
              user={user}
              fieldToValidate="isAdmin"
            >
              <section className="py-4 d-flex flex-column">
                <button className="btn-primary mx-auto mb-3">
                  <Link to="/create/article">Crear Artículo</Link>
                </button>
                <ArticlesList />
              </section>
            </PrivateRoute>
            <PrivateRoute
              path={`${url}/participants`}
              user={user}
              fieldToValidate="isAdmin"
            >
              <section className="py-4 d-flex flex-column">
                <button className="btn-primary mx-auto mb-3">
                  <Link to="/create/participant">Crear Participante</Link>
                </button>
              <ParticipantsList />
              </section>
            </PrivateRoute>
          </div>
        </div>
      </div>
    </div>
  );
};
