import React from 'react';

export const StatsCard = ({ number, text }) => (
  <div className="col-lg-4 col-sm-6 col-6">
    <div className="statics-item">
      <h3>{number}</h3>
      <div className="statics-item-desc">
        <p>{text}</p>
      </div>
    </div>
  </div>
);
