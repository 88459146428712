import React, { useState, useEffect } from 'react';
import { ArticlesItem } from './ArticlesItem';
import { articleService } from '../../services/article.services';
import { entriesService } from '../../services/entries.services';
import swal from 'sweetalert';
import { Link, useHistory } from 'react-router-dom';

export const ArticlesList = () => {
  const [articles, setArticles] = useState([]);
const history = useHistory()
  // receives as a parameter a function that will be executed every
  // time our component is rendered
  useEffect(() => {
      getData();
  }, []);

  // the function "getData" is setting the data
  const getData = () => {
    // call the service and use a method "getAll" to return all the articles
    articleService
      .getAll()
      .then((articles) => {
        setArticles(articles.data);
      })
      .catch((err) => {
        swal('Ha ocurrido un error', 'Intente nuevamente más tarde', 'error');
      });
  };

  const handleDelete = (id) => {
    const confirmed = window.confirm('¿Confirma eliminación?');
    if(!confirmed) {
      return;
    }
    entriesService
      .delete(id)
      .then((res) => {
        setArticles(
          articles.map((el) =>
            el.id === id ? { id: id, title: null, createdAt: null } : el
          )
        );
      })
      .catch((err) => {
        swal('Ha ocurrido un error', 'Intente nuevamente más tarde', 'error');
      });
  };

  const handleEdit = (e) => {
    history.push('/edit/article')
  };

  return (
    <div className="account-content pledges-received account-table">
      <h3 className="account-title">Administrar Publicaciones</h3>
      <div className="account-main">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Titulo</th>
              <th>Fecha</th>
              <th>Editar</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {articles &&
              articles.map((el) => (
                <ArticlesItem
                  key={el.id}
                  id={el.id}
                  title={el.title}
                  date={el.createdAt}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
