import API from './config.services';

const ENTRIES_ENDPOINT = '/entries/';

export const entriesService = {
  delete: (id) =>
    new Promise((resolve, reject) => {
      API.delete(ENTRIES_ENDPOINT + id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })
};
