import React, {useEffect, useState} from 'react';
import {ongService} from '../../services/ong.services';
import {participantsService} from '../../services/participants.services';
import {StatsCard} from './StatsCard';
import {TeamCard} from './TeamCard';
import swal from 'sweetalert';
import {useHistory} from 'react-router-dom';

export const About = () => {
  let [desc, setDesc] = useState ({});
  let [participants, setParticipants] = useState ([]);
  let history = useHistory ();

  useEffect (() => {
    ongService
      .getDescription ()
      .then (res => {
        setDesc (res);
      })
      .catch (error => {
        swal (
          'Ha ocurrido un error',
          'Intente nuevamente más tarde.',
          'error'
        ).then (() => {
          history.push ('/');
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    participantsService
      .getAll ()
      .then (res => {
        console.log (res);
        setParticipants (res);
      })
      .catch (error => {
        swal (
          'Ha ocurrido un error',
          'Intente nuevamente más tarde.',
          'error'
        ).then (() => {
          history.push ('/');
        });
      });
  }, []);

  return (
    <main id="main" className="site-main">
      <section
        className="top-site"
        style={{backgroundImage: `url(/images/nosotros_landing.jpg)`}}
      >
        <div className="container">
          <h1>{desc && desc.short_description}</h1>
        </div>
      </section>
      <div className="container">
        <div className="video">
          <div className="video-wrapper">
            <video height={315} controls autoPlay={true} muted> 
              <source src="/images/nosotros_video.mp4" type="video/mp4" />

            </video>

          </div>
        </div>
        <section className="statics section">
          <h2 className="title">{desc && desc.name}</h2>
          <div className="description">
            <p>{desc && desc.long_description}</p>
          </div>
          <div className="statics-content">
            <div className="row">
              <StatsCard number="+400" text="Personas ayudadas" />
              <StatsCard number="+50" text="Colaboradores" />
              <StatsCard
                number="+13"
                text="Años ayudando a quienes nos necesitan"
              />
            </div>
          </div>
        </section>
        <section className="team section">
          <h2 className="title">Conocé a nuestro equipo</h2>
          <div className="description" />
          <div className="team-content">
            <div className="row">
              {participants &&
                participants.map (e => (
                  <TeamCard
                    {...e}
                    key={e.name}
                    ln={e.linkedinUrl}
                    fb={e.facebookUrl}
                    in={e.instagramUrl}
                  />
                ))}
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
