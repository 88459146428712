import React, { useEffect, useState } from 'react';
import { ongService } from '../../../services/ong.services';

export const FooterSocialMedia = () => {
  let [links, setLinks] = useState({});
  useEffect(() => {
    ongService
      .getLinks()
      .then((res) => {
        setLinks({ instagram: res.instagramUrl, facebook: res.facebookUrl });
      })
      .catch((err) => {
        setLinks({ instagram: '', facebook: '' });
      });
  }, []);
  return (
    <div className="footer-menu-item newsletter">
      <div className="follow">
        <h3>Seguinos!</h3>
        <div>
          <ul>
            <li className="facebook">
              <a
                target="_blank"
                href={links.facebook}
                rel="noopener noreferrer"
              >
                {' '}
                <i className="fa fa-facebook" aria-hidden="true" />
              </a>
            </li>

            <li className="instagram">
              <a
                target="_blank"
                href={links.instagram}
                rel="noopener noreferrer"
              >
                {' '}
                <i className="fa fa-instagram" aria-hidden="true" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
