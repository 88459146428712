import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import userService from '../../services/users.services';
import { logoutUser } from '../../redux/actions/userActions';

export const Profile = () => {
  const user = useSelector((state) => state.user);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleDelete = () => {
    const confirmed = window.confirm('¿Confirma eliminación?');
    if(!confirmed) {
      return;
    }
    
    let id = user.id;
    userService
      .delete(id)
      .then(() => {
        dispatch(logoutUser());                
        history.push('/');       
        swal('Ok!', 'Usuario eliminado', 'success');        
      })
      .catch((err) => {
        swal('Ha ocurrido un error', 'Intente nuevamente más tarde', 'error');
      });
  };

  return (
    <div className="account-content profile">
      <h3 className="account-title">Mi perfil</h3>
      <div className="account-main">
        <div className="author clearfix">
          
          <div className="author-content">
            <div className="author-title">
              <h3>
                <a href="/">{user.name}</a>
              </h3>
            </div>
            <div className="author-info">
              <p>{user.email}</p>
              <p>{user.info}</p>
            </div>
          </div>
        </div>
       <div>
          <Link
            className="edit-profile btn-primary"
            to={`/editProfile/${user.id}`}
          >
            Editar Perfil
          </Link>          
          <button 
            className="btn-primary" 
            onClick={handleDelete}            
          >
            Eliminar cuenta
          </button>
        </div>       
      </div>
    </div>
  );
};
