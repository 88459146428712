import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {ongService} from '../../services/ong.services';
import {useDispatch, useSelector} from 'react-redux';
import {logoutUser} from '../../redux/actions/userActions';

// export the const "Header" to include on the "App.js"
export const Header = () => {
  const user = useSelector (state => state.user);
  const dispatch = useDispatch ();

  // "data" contain the information of the ONG
  const [data, setData] = useState ([]);

  // receives as a parameter a function that will be executed every time our component is rendered
  // in this case it receives "getData"
  useEffect (() => {
    getData ();
  }, []);

  // the function "getData" is setting the data
  const getData = () => {
    // call the service and use a method "getAll" to return all the users
    ongService
      .getData ()
      .then (data => {
        // setting the data of the user
        setData (data);
      })
      .catch (err => {
        // if there are an Error, we show it on console
        console.log ('Error inesperado', err);
      });
  };

  return (
    <div id="wrapper">
      <header id="header" className="site-header border border-bottom fixed">
        <div className="content-header">
          <div className="container">
            <div className="site-brand">
              <Link to="/" aria-hidden="true">
                <img
                  className="siteLogo"
                  src={`/images/${data.image}`}
                  alt=""
                />
                {/* {data.image} */}
              </Link>
            </div>
            <div className="right-header">
              <nav className="main-menu">
                <button className="c-hamburger c-hamburger--htx">
                  <span />
                </button>
                <ul>
                  <li>
                    <Link to="/" aria-hidden="true">
                      Inicio
                    </Link>
                  </li>
                  <li>
                    <Link to="/nosotras" aria-hidden="true">
                      Nosotras
                    </Link>
                  </li>
                  <li>
                    <Link to="/novedades" aria-hidden="true">
                      Prensa
                    </Link>
                  </li>
                  <li>
                    <Link to="/articulos">
                      Publicaciones
                    </Link>
                  </li>

                  <li>
                    <Link to="/contacto" aria-hidden="true">
                      Contacto
                    </Link>
                  </li>
                  <li>
                    <div className="login login-button">
                      <Link
                        to="/donations"
                        className="btn-primary"
                        style={{letterSpacing: 'initial', color: 'white'}}
                      >
                        Donar
                      </Link>
                    </div>
                  </li>

                  {user.loggedIn &&
                    <li>
                      <Link to="/account/dashboard" aria-hidden="true">
                        Cuenta
                      </Link>
                    </li>}
                </ul>
              </nav>
              {user.loggedIn === true
                ? <div className="login login-button">
                    <Link
                      to="/"
                      className="btn-primary"
                      onClick={() => dispatch (logoutUser ())}
                    >
                      Salir
                    </Link>
                  </div>
                : <div className="login login-button">
                    <Link to="/login" className="btn-primary">
                      Ingresar
                    </Link>
                  </div>}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
