import React, { useState, useEffect } from 'react';
import { NewsItem } from './NewsItem';
import { newsService } from '../../services/news.services';
import { entriesService } from '../../services/entries.services';
import swal from 'sweetalert';

export const NewsList = () => {
  const [news, setNews] = useState([]);

  // receives as a parameter a function that will be executed every
  // time our component is rendered
  useEffect(() => {
    setTimeout(() => {
      getData();
    }, 300);
  }, []);

  // the function "getData" is setting the data
  const getData = () => {
    // call the service and use a method "getAll" to return all the news
    newsService
      .getAll()
      .then((news) => {
        setNews(news);
      })
      .catch((err) => {
        swal('Ha ocurrido un error', 'Intente nuevamente más tarde', 'error');
      });
  };

  //the function "handleDelete" is soft delete the new by id
  const handleDelete = (id) => {
    const confirmed = window.confirm('¿Confirma eliminación?');
    if(!confirmed) {
      return;
    }
    
    //Call the service delete to soft delete new by id.
    entriesService
      .delete(id)
      .then((res) => {
        setNews(
          news.map((el) =>
            el.id === id ? { id: id, title: null, createdAt: null } : el
          )
        );
      })
      .catch((err) => {
        swal('Ha ocurrido un error', 'Intente nuevamente más tarde', 'error');
      });
  };

  const handleEdit = (e) => {
    alert('edit');
  };

  return (
    <div className="account-content pledges-received account-table">
      <h3 className="account-title">Administrar prensa</h3>
      <div className="account-main">
        <table>
          <thead>
            <tr>
              <th> ID</th>
              <th> Titulo</th>
              <th> Fecha</th>
              <th> Editar</th>
              <th> Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {news &&
              news.map((el) => (
                <NewsItem
                  key={el.id}
                  id={el.id}
                  title={el.title}
                  date={el.createdAt}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
