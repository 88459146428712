import React from 'react';

export const NewDetailsBody = (props) => {  
  return (
      <div 
       dangerouslySetInnerHTML={{
        __html: props.content
      }}></div>      
  );  
}
