import API from './config.services';

const PARTICIPANTS_ENDPOINT = {
  LIST_PARTICIPANTS:'/participants',
  PARTICIPANTS_DATA:'/participants/',
  REDUCED: '/participants/reduced'
}

export const participantsService = {
  getAll: () =>
    new Promise((resolve, reject) => {
      API.get(PARTICIPANTS_ENDPOINT.LIST_PARTICIPANTS)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  get: (id) =>
  new Promise((resolve, reject) => {
    API.get(PARTICIPANTS_ENDPOINT.PARTICIPANTS_DATA + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  }),
  postParticipant: (data) => 
  new Promise((resolve, reject) => {
    API.post(PARTICIPANTS_ENDPOINT.PARTICIPANTS_DATA, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  }),

  getReduced: () =>
    new Promise((resolve, reject) => {
      API.get(PARTICIPANTS_ENDPOINT.REDUCED )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  getReduced: () =>
    new Promise((resolve, reject) => {
      API.get(PARTICIPANTS_ENDPOINT.REDUCED)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  delete: (id) =>
    new Promise((resolve, reject) => {
      API.delete(PARTICIPANTS_ENDPOINT.PARTICIPANTS_DATA + id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),
    updateParticipant: (id, data) => 
    new Promise((resolve, reject) => {
      API.put(PARTICIPANTS_ENDPOINT.PARTICIPANTS_DATA + id, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),
};
