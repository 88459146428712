import React from 'react';
import { BrowserRouter as Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import API from '../../services/config.services';
import BASE_URL from '../../services/base.service';

export const Events = ({ news }) => {
  // declare a new variable of state to redirect
  const [redirect, setRedirect] = React.useState([]);

  // function to redirect to "Home"
  const renderRedirect = (id) => {
    if (redirect === true) {
      return <Redirect to={'/articulo/' + id} />;
    }
  };

  const onClick = () => {
    setRedirect(true);
  };

  const renderImage = (imageToCheck) => {
    if(imageToCheck) {
      if(imageToCheck[0] == '/') {
        return BASE_URL + imageToCheck
      }
      return imageToCheck
    }
  }

  return (
    <>
      {renderRedirect(news.id)}
      <article className="post">
        <div className="blognews-thumb">
          <a className="overlay" href="#" onClick={onClick}>
            <img
              className="fit"
              src={renderImage(news.image)}
              style={{ width: '630px', height: '380px' }}
              alt=""
            />

            <span className="ion-ios-search-strong"></span>
          </a>
        </div>
        <div className="post-info">
          <h5 className="post-title">
            <a href="">{news.title}</a>
          </h5>
         
          <div >
            
            <p
            
            > {news.content && news.content.length <= 100
                ? news.content.replace(/<[^>]*>?/gm, '')
                : news.content.replace(/<[^>]*>?/gm, '').substring(100, -1) + '...'}</p>
          </div>
          <Link to={`/news/${news.id}`}>
            <button className="btn btn-info" onClick={onClick}>
              Ver Más
            </button>
          </Link>
        </div>
      </article>
    </>
  );
};
