import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { string, object } from 'yup';
import { ContactWidget } from './Contact-widget';

const ContactSchema = object().shape({
  name: string().required('Completa tu nombre y apellido, por favor.'),
  subject: string().required('Completa el asunto del mensaje, por favor.'),
  email: string()
    .email('El correo ingresado no es válido.')
    .required('Ingrese un email, por favor.'),
  message: string().required('Escribe un mensaje, por favor.')
});

const submitHandle = (values, { setSubmitting }) => {
  setTimeout(() => {
    alert(JSON.stringify(values, null, 2));
    setSubmitting(false);
  }, 400);
};

export const Contact = () => {
  return (
    <div className="page-content contact-content mb-5 site-main">
      <div className="page-title background-blog">
        <div className="container">
          <h1>Contacto</h1>
          <div className="breadcrumbs">
            <ul>
              <li>
                <a href="#">
                  Inicio
                    </a>
                <span>/</span>
              </li>
              <li> Contacto </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 main-content">
            <div className="entry-content">
              <div className="row">
                <div className="col-lg-8">
                  <div className="form-contact">
                    <h2>Contáctenos</h2>
                    <Formik
                      initialValues={{
                        name: '',
                        subject: '',
                        email: '',
                        message: ''
                      }}
                      validationSchema={ContactSchema}
                      onSubmit={submitHandle}
                    >
                      {({ isSubmitting }) => (
                        <Form id="contactForm" className="clearfix">
                          {' '}
                          <div className="clearfix">
                            {' '}
                            <div className="field align-left">
                              {' '}
                              <Field
                                name="name"
                                placeholder="Nombre y Apellido"
                              />
                              <ErrorMessage name="name" component="div" />
                            </div>
                            <div className="field align-right">
                              <Field name="email" placeholder="Email" />
                              <ErrorMessage name="email" component="div" />
                            </div>
                          </div>
                          <div className="field">
                            <Field
                              type="text"
                              name="subject"
                              placeholder="Asunto"
                            />
                            <ErrorMessage name="subject" component="div" />
                          </div>
                          <div className="field-textarea">
                            <ErrorMessage name="message" component="div" />
                            <Field
                              as="textarea"
                              name="message"
                              placeholder="Escriba su mensaje aquí..."
                            />
                          </div>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn-primary "
                          >
                            Enviar
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                <ContactWidget />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
