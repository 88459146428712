import React, { useState, useEffect } from 'react';
import { newsService } from '../../services/news.services';
import { News } from './News';
import { Events } from './Events';
import { articleService } from '../../services/article.services';
import {
  useLocation
} from "react-router-dom";
export const Entries = (props) => {
  const entriesLocation = useLocation()
  const [news, setNews] = useState([
   
  ]);
  const [articles, setArticles] = useState([
  
  ]);

  // spread the route's properties
  const { ...pageProps } = props;
  // compare if we are in /novedades or /eventos to select correct content to display
  const contentType = pageProps.match.path === '/novedades' ? 'novedades' : '';
  // based on contentType, show info matching it
  const [title, subTitle, entriesContent] = contentType
    ? [
      'Fortalecer sin Fronteras',
      'Prensa',
      news.map((news) => <News key={news.id} news={news} />)
    ]
    : [
      'Fortalecer sin Fronteras',
      'Publicaciones ',
      articles.map((event) => <Events key={event.id} news={event} />)
    ];

  useEffect(() => {
    getNewsData();
  }, []);

  useEffect(() => {
    getNewsData();
  }, [entriesLocation]);


  // the function "getData" is setting the data
  const getNewsData = () => {

    const contentType = pageProps.match.path === '/novedades' ? 'novedades' : '';

    if(contentType) {
      newsService
      .getAll()
      .then((news) => {
        // setting the data of the news
        setNews(news);
      })
      .catch((err) => {
        // if there are an Error, we show it on console
        console.log('Error inesperado', err);
      });
    }else {
      articleService
      .getAll()
      .then((news) => {
        // setting the data of the news
        setArticles(news.data);
      })
      .catch((err) => {
        // if there are an Error, we show it on console
        console.log('Error inesperado', err);
      });
    }

    // call the service and use a method "getAll" to return all the news
  
  };

  return (
    <div className="blog-grid full-width">
      <div id="wrapper">
        <main id="main" className="site-main">
          <div className="page-title background-blog">
            <div className="container">
              <h1>{title}</h1>
              <div className="breadcrumbs">
                <ul>
                  <li>
                    <a href="#">
                      Inicio
                    </a>
                    <span>/</span>
                  </li>
                  <li> {subTitle} </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 main-content">
                <div className="grid-post">{entriesContent}</div>
           
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

/* 
     <div className="page-navigation">
                  <span className="page-numbers current">1</span>
                  <a
                    href="https://preview.themeunite.com/ideapress/blog_grid.html#"
                    className="page-numbers"
                  >
                    2
                  </a>
                  <a
                    href="https://preview.themeunite.com/ideapress/blog_grid.html#"
                    className="page-numbers"
                  >
                    3
                  </a>
                </div>
*/
