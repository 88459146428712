import React from 'react';
import userService from '../../services/users.services';


export const Users = () => {
  // declare a new variable of state, wich we will call "user"
  // "user" containr the information of the user
  const [user, setUser] = React.useState([]);

  // receives as a parameter a function that will be executed every time our component is rendered
  // in this case it received "getData"
  React.useEffect(() => {
    getData();
  }, []);

  // the function "getData" is setting the data
  const getData = () => {
    // call the service and use a method "getAll" to return all the users
    userService
      .getAll()
      .then((user) => {
        // setting the data of the user
        setUser(user);
      })
      .catch((err) => {
        // if there are an Error, we show it on console
        console.log('Error inesperado', err);
      });
  };

  return (
    <div>
      <h2>Users info</h2>
      {
        // we are use map to iterate all the element to it have the "user"
        user.map((elem) => {
          return (
            <div key={elem.id}>
              <h4>{elem.id}- {elem.firstName} {elem.lastName}</h4>
            </div>
          );
        })
      }
    </div>
  );
}
