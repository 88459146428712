import React from 'react';

export const NewDetailsDate = (props) => {
  return (
    <div>
      <div key={props.id}>
        <i className="fa fa-calendar-check-o" aria-hidden="true" />
        <a href="/">{props.createdAt}</a>
      </div>
    </div>
  );
};
