import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation } from 'react-router-dom';
import { string, object, mixed } from 'yup';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { newsService } from '../../services/news.services';
import { articleService } from '../../services/article.services';
import { Link, useHistory } from 'react-router-dom';
import { ArticlesList } from './ArticlesList';
import { BASE_URL } from '../../services/config.services';

const createImgValidatorObject = (name) => ({
  name: name,
  message: 'Formato no soportado. Elija una imagen .jpg, .jpeg, .png o .gif.',
  test: (value) =>
    value &&
    ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(value.type)
});

const validateText = () => string().required('Complete el campo.');

const validateImage = (name) =>
  mixed().required('Suba una imagen.').test(createImgValidatorObject(name));

const entrySchema = object().shape({
  title: validateText(),
});

export const EntriesForm = () => {
  const history = useHistory();
  const location = useLocation();
  const [type, setType] = useState('');
  const [err, setErr] = useState(false);
  const [url, setUrl] = useState('');
  const [visibility, setVisibility] = useState(false);
  const [ckState, setCKState] = useState('');
  const [newsData, setNewsData] = useState(false);
  const [formContent, setFormContent] = useState(null);
  const [entityId, setEntityId] = useState(null);
  const [entyryType, setEntyType] = useState(null);



  const handleChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setUrl(event.target.result);
      setVisibility(true);
      setFieldValue(`image`, file);
    };
  };

  useEffect(() => {
    let splitted = location.pathname.split('/')[2];
    let type = splitted == 'article' ? 'artículo' : 'novedad';
    setType(type);
  }, []);

  useEffect(() => {
    if (location.pathname.split('/')[1] === 'edit') {
      let id = location.pathname.split('/')[3];
      setEntityId(id)

      if (type == 'articulo') {
        articleService.get(id).then((data) => {
          setFormContent(data)
          if(data.image) {
            setUrl(data.image)

          }
          setCKState(data.content);

        })
      } else {
        newsService.get(id).then((data) => {
          setFormContent(data)
          setCKState(data.content);
          if(data.image) {
            setUrl(BASE_URL + data.image)
            setVisibility(true)
          }
        })
      }

    };
  }, []);


  const handleRemove = (e, values) => {
    e.preventDefault();
    values.image = '';
    setUrl('');
    setVisibility(false);
  };

  const submitHandle = (values, { setSubmitting }) => {

    if (ckState == '') {
      setErr(true);
      setSubmitting(false);
    } else {
      let formData = new FormData();
      const type = location.pathname.split('/')[2];
      formData.append('type', type);
      formData.append('content', ckState);
      for (let prop in values) {
        formData.append(prop, values[prop]);
      }
      console.log(entityId)
      //connection with API
      if (type == 'articulo') {
        if(!entityId) {
          articleService.post(formData).then(() => history.push('/account/articles'));;

        }else 
        {
          articleService.
          put(formData, entityId).then(() => history.push('/account/articles'));

        }
        

      } else {
        if(!entityId) {
          newsService.post(formData).then(() => history.push('/account/novedades'));

        }else {
          newsService.put(formData, entityId).then(() => history.push('/account/novedades'));

        }

      }
      //show formData
      for (var prop of formData.entries()) {
        console.log(prop[0] + ': ' + prop[1]);
      }
      setSubmitting(false);
    }
  };

  const capitalizeFirst = (str) => str[0].toUpperCase() + str.slice(1);



  const handleCkeditorState = (event, editor) => {
    setErr(false);
    const data = editor.getData();
    setCKState(data);
  };

  const getData = () => formContent ? formContent : {
    title: '',
    image: ''
  }


  return (
    <div className="campaign-form form-update mb-5 account-wrapper">
      <div className="container">
        <div className="row">
          <Formik
            initialValues={getData()}
            enableReinitialize={true}
            validationSchema={entrySchema}
            onSubmit={submitHandle}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <h4>Cree su {type && capitalizeFirst(type)}</h4>
                <div className="field">
                  <label htmlFor="title">Titulo de {type}.</label>
                  <Field
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Título del artículo"
                  />
                  <ErrorMessage name="title" component="div" />
                </div>
                <div className="field">
                  <label htmlFor="content">Contenido de {type}.</label>
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={handleCkeditorState}
                    data={ckState}
                    name="content"
                    id="content"
                  />
                  {err === true && <div>Complete el campo.</div>}
                </div>

                <div className="upload-bg">
                  <label
                    htmlFor="upload"
                    style={{ display: 'block', width: '100%' }}
                  >
                    <div id="boxchoice">
                      <div
                        className="choicefile"
                        style={
                          visibility === false
                            ? { display: 'block' }
                            : { display: 'none' }
                        }
                      >
                        <i className="fa fa-cloud-upload" aria-hidden="true" />
                        {'  '}Suba su imagen
                      </div>
                      <p />
                    </div>
                  </label>
                  <Field
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="upload"
                    name="uploadfile"
                    onChange={(e) => handleChange(e, setFieldValue)}
                  />

                  {values[`image`] !== '' && (
                    <div id="thumbbox">
                      <img
                        style={
                          visibility === true
                            ? { display: 'block' }
                            : { display: 'none' }
                        }
                        src={url}
                        alt="selection"
                        id="myimage"
                      />
                      <button
                        className="removeimg"
                        onClick={(e) => handleRemove(e, values)}
                      ></button>
                    </div>
                  )}

                  <ErrorMessage name="image" component="div" />
                  <label className="filename" />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn-primary "
                >
                  Enviar
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
