import React, { useState, useEffect } from 'react';
import { NewsItem } from './NewsItem';
import { newsService } from '../../services/news.services';
import { entriesService } from '../../services/entries.services';
import swal from 'sweetalert';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ongService } from '../../services/ong.services';



export const MercadoPago = () => {
  const [news, setNews] = useState([]);
  const [formContent, setFormContent] = useState('');

  // receives as a parameter a function that will be executed every
  // time our component is rendered
  useEffect(() => {
    setTimeout(() => {
        getInitialData();
    }, 300);
  }, []);

  // the function "getData" is setting the data
  const getInitialData = () => {
    // call the service and use a method "getAll" to return all the news
    ongService.getData().then((data) => {

        if(data.mercadopago) {
            setFormContent({mercadopago: data.mercadopago})
        }

    })
    newsService
      .getAll()
      .then((news) => {
        setNews(news);
      })
      .catch((err) => {
        swal('Ha ocurrido un error', 'Intente nuevamente más tarde', 'error');
      });
  };

  const submitEditHandle = async (values) => {
    console.log(values);
    try {
   
        await ongService.put(values)
   
    } catch (error) {
        console.log(error)
      swal('Ooops!', 'There was an error', 'error');
    }
  };


 

  const handleEdit = (e) => {
    alert('edit');
  };

  const getData = () => formContent ? formContent :  {
    mercadopago: '',
   
    }


  return (
    <div className="account-content pledges-received account-table">
      <h3 className="account-title">Modificar MercadoPago </h3>
      <div className="account-main">
      <div className="">
          <p> Ingrese código del botón </p>
          <Formik
            initialValues={{
              mercadopago: '',
            }}
            initialValues={getData()}
          enableReinitialize={true}
            onSubmit={submitEditHandle}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="field">
                  <Field name="mercadopago" as="textarea" />
                </div>
              
                <div className="inline clearfix">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn-primary"
                  >
                    Editar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
         </div>
    </div>
  );
};
