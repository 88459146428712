import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Dashboard = () => {
  const user = useSelector((state) => state.user);
  return (
    <div className="account-content dashboard">
      <h3 className="account-title">Escritorio</h3>
      <div className="account-main">
        <div className="author clearfix">
         
          <div className="author-content">
            <div className="author-title">
              <h3>
                <a href="/">{user.name}</a>
              </h3>

              <Link className="edit-profile" to={`/editProfile/${user.id}`}>
                Editar Perfil
              </Link>
            </div>
            <div className="author-info">
              <p>{user.email}</p>
              <p>{user.info}</p>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  );
};
