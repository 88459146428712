import React, { useEffect, useState } from 'react';
import { Card } from './Card';
import { newsService } from '../../../services/news.services.js';
import BASE_URL from '../../../services/base.service';

//Call this component from any page needed. It will expose a list of cards holding fetched data
//Future aproach : Do not hold CardList responsible for fetching his own data, instead pass it as props.
export const CardList = () => {
  //State that holds card's fetched data
  const [cardData, setCardData] = useState([]);

  //useEffect runs getData only one time  after Home is properly mounted.
  useEffect(() => {
    getData();
  }, []);

  //Helper that fetches data
  const getData = () => {
    // Calling the news service
    newsService
      .getAll()
      .then((data) => {
        // Set the data in the state
        setCardData(data);
      })
      .catch((err) => console.log(err)); // if there is an error...
  };
  console.log(cardData)

  const renderImage = (imageToCheck) => {
    if(imageToCheck) {
      if(imageToCheck[0] == '/') {
        return BASE_URL + imageToCheck
      }
      return imageToCheck
    }
  }

  const cardList = cardData.map((card, index) => {
    return (
      <Card
        key={index}
        index={index}
        title={card.title}
        content={card.content.replace(/<[^>]*>?/gm, '')}
        date={card.date}
        image={renderImage(card.image)}
        id={card.id}
      />
    );
  });

  return (
    <div className="blognews mt-5 ">
      <div className="container">
        <h2 className="title mb-5">Actividades</h2>
        <div className="blognews-content">
          <div className="row">{cardList}</div>
        </div>
      </div>
    </div>
  );
};
