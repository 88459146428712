import API from './config.services';

// create an Object to send the URI to access
const NEWS_ENDPOINT = {
  LIST_NEWS: '/news', // URI to get all news
  NEWS_DATA: '/news/' //URI to get the news data by id
  // in the future there will be more URIs
};

// create a Object wich contain two functions type promise
// when we call the API this return async data
export const newsService = {
  // function to return the list of news
  getAll: () =>
    new Promise((resolve, reject) => {
      API.get(NEWS_ENDPOINT.LIST_NEWS)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  // function to return a news by id
  get: (id) =>
    new Promise((resolve, reject) => {
      API.get(NEWS_ENDPOINT.NEWS_DATA + id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

  // function to post new data
  post: (data) =>
    new Promise((resolve, reject) => {
      console.log(data);
      API.post(NEWS_ENDPOINT.NEWS_DATA, data)
        .then((res) => {
          resolve(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    }),

    put: (data, id) =>
    new Promise((resolve, reject) => {
      console.log(data);
      API.put(NEWS_ENDPOINT.NEWS_DATA + '/' + id, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    })

};
