import React from 'react';
import { Link } from 'react-router-dom';


export const ParticipantItem = ({ id, name, handleEdit, handleDelete }) => {

  const deleteItem = (e) => {
    handleDelete(id);
  };

  return (
    name && (
      <tr>
        <td>{id}</td>
        <td>{name}</td>
        <td>
          <Link to={`/edit/participant/${id}`} >
          <span className="ion-edit pointer"></span>
          </Link>
        </td>
        <td>
          <span
            onClick={deleteItem}
            className="ion-android-delete pointer"
          ></span>
        </td>
      </tr>
    )
  );
};
