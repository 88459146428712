import React from 'react';

//Sub-components
import { FooterSocialMedia } from './Footer-social-media/Footer-social-media';
import { FooterLegals } from './Footer-legals/Footer-legals';
import { FooterContact } from './FooterContact';

export const Footer = () => {
  return (
    <div>
      <footer id="footer" className="site-footer">
        <div className="footer-menu">
          <div className="container d-flex overflow-hidden">
            <div className="container d-flex justify-content-center">
              <FooterSocialMedia />
            </div>
            <div className="container d-flex justify-content-center">
              <FooterContact />
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <FooterLegals />
        </div>
      </footer>
    </div>
  );
};
