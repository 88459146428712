import React from 'react';

export const NewDetailsTitle = (props) => {
  return (
    <div className="page-title background-blog">
        <div className="container">          
          <h1 key={props.id}>{props.title}</h1>          
        <div className="breadcrumbs">            
          <ul>
            <li>
              <a href="index.html">Home</a>
              <span>/</span>
            </li>
            <li>
              Novedades<span>/</span>
            </li>              
              <li key={props.id}> {props.title} </li>              
          </ul>
        </div>          
      </div>
    </div>
  );
}
