import React, { useEffect, useState } from 'react';
import { ongService } from '../../services/ong.services';
import { Link } from 'react-router-dom';

export const FooterContact = () => {
  let [contactInfo, setContactInfo] = useState({});

  useEffect(() => {
    ongService
      .getData()
      .then((res) => {
        setContactInfo({ email: res.email, phone: res.phone });
      })
      .catch((error) => {
        setContactInfo({});
      });
  }, []);

  return (
    <div className="footer-menu-item">
      <div className="follow">
        <h3>Contactanos!</h3>
      </div>
      <ul>
        <li className="">
          <i className="fa fa-phone mr-2" aria-hidden="true" />
          {contactInfo.phone}
        </li>
        <li className="d-flex justify-content-center">
          <Link to="/contact" aria-hidden="true">
            <i className="fa fa-envelope-o mr-2" aria-hidden="true" />
            <span>{contactInfo.email}</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
