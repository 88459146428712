import React from 'react';

export const NewDetailsCategory = (props) => {
  return (
    <div key={props.id}>
      <i className="fa fa-folder-o" aria-hidden="true" />
      <a href="/">{props.categoryId}</a>
    </div>
  );
};
