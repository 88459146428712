import React, { useState, useEffect } from 'react';

// export the function "Profile" to include on the "App.js"
export const Profile = () => {
  // declare a new variable of state, wich we will call "user"
  // "user" contain the information of the User
  const [user, setUser] = useState([]);

  // receives as a parameter a function that will be executed every time our component is rendered
  // in this case it received "getData"
  useEffect(() => {
    getData();
  }, []);

  // the function "getData" is setting the data below
  // the data "res" emulate a MOCK
  const getData = () => {
    // "res" contain the info of the user
    const res = {
      name: 'Alan',
      surname: 'Di Masi',
      email: 'alan19912@gmail.com',
      photo: 'dashboard-avatar.png',
      log: false
    };

    // setting the data
    setUser(res);
    // print to console
    console.log(res);
  };

  // return the view
  return (
    <main id="main" className="site-main">
      <div className="page-title background-page">
        <div className="container">
          <h1>Profile</h1>
          <div className="breadcrumbs">
            <ul>
              <li>
                <a href="index.html">Home</a>
                <span>/</span>
              </li>
              <li>Profile</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="account-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <nav className="account-bar">
                <ul>
                  <li className="active">
                    <a href="profile.html">Profile</a>
                  </li>
                </ul>
              </nav>
            </div>
            {
              // ternary operator of "user" to search if the user is log in or not
              user.log === true ? (
                // if the user is login (is true) return the profile
                <div className="col-lg-9" key={user}>
                  <div className="account-content profile">
                    <h3 className="account-title">My Profiles</h3>
                    <div className="account-main">
                      <div className="author clearfix">
                        <a className="author-avatar" href="/">
                          <img src={`images/${user.photo}`} alt="" />
                        </a>
                        <div className="author-content">
                          <div className="author-title">
                            <h3>
                              <a href="/">{user.name}</a>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="profile-box">
                        <h3>Profile Infomations</h3>
                        <ul>
                          <li>
                            <strong>First Name</strong>
                            <div className="profile-text">
                              <p>{user.name}</p>
                            </div>
                          </li>
                          <li>
                            <strong>Last Name:</strong>
                            <div className="profile-text">
                              <p>{user.surname}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <button href="/" className="btn-primary">
                        Edit Profiles
                      </button>
                      <br />
                      <br />
                      <a href="/" className="btn-primary">
                        Delete Profiles
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                // if the user is not login (is false) return a message
                <div className="col-lg-9" key={user}>
                  <div className="account-content profile">
                    <h3 className="account-title">My Profiles</h3>
                    <div className="account-main">
                      <h1>Usted no esta logueado</h1>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </main>
  );
};
