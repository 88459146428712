import React from 'react';

export const TeamCard = ({ name, image, text, ins, fb, ln }) => {
  
  if(image.includes('participants')) {
    image = 'https://ongs.alkemy.org/fortalecer' + image
  }
  return (
  <div className="col-lg-3 col-sm-6 col-6 mb-3">
    <div className="team-item">
      <div className="avatar">
        <img src={image} alt="" />
      </div>
      <div className="team-info">
        <ul className="socials">
          <li>
            <a href={fb}>
              <i className="fa fa-facebook" aria-hidden="true" />
              {ins}
            </a>
          </li>
          <li>
            <a href={ins}>
              <i className="fa fa-instagram" aria-hidden="true" />
            </a>
          </li>
          <li>
            <a href={ln}>
              <i className="fa fa-linkedin" aria-hidden="true" />
            </a>
          </li>
        </ul>
        <h3 className="team-name">{name}</h3>
        <span className="team-job">{text}</span>
      </div>
    </div>
  </div>
)};
