import React from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { string, object } from 'yup';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { editUser } from '../../redux/actions/userActions';
import { useDispatch } from 'react-redux';

const editSchema = object().shape({
  firstName: string().required('Completa tu nombre, por favor.'),
  lastName: string().required('Completa tu apellido, por favor.')
});

export const EditProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const submitEditHandle = async (values) => {
    console.log(values);
    try {
      const data = JSON.stringify(values);
      const body = JSON.parse(data);
      dispatch(editUser(body));
      history.push('/account/dashboard');
      swal('yes!', 'Editing success', 'success');
    } catch (error) {
      swal('Ooops!', 'There was an error', 'error');
    }
  };

  return (
    <div className="container my-5">
      <div className="main-content">
        <div className="form-login form-register">
          <h2>Editar una cuenta</h2>
          <Formik
            initialValues={{
              firstName: '',
              lastName: ''
            }}
            validationSchema={editSchema}
            onSubmit={submitEditHandle}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="field">
                  <Field name="firstName" placeholder="Nombre" />
                  <ErrorMessage name="firstName" component="div" />
                </div>
                <div className="field">
                  <Field name="lastName" placeholder="Apellido" />
                  <ErrorMessage name="lastName" component="div" />
                </div>
                <div className="inline clearfix">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn-primary"
                  >
                    Editar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
