import React from 'react';

export const UserItem = ({ id, firstName, lastName, email, handleEdit, handleDelete }) => {
  const editItem = (e) => {
    handleEdit(id);
  };

  const deleteItem = (e) => {
    handleDelete(id);
  };

  return (    
      <tr>
        <td>{id}</td>
        <td>{firstName}</td>
        <td>{lastName}</td>
        <td>{email}</td>
        <td>
          <span onClick={editItem} className="ion-edit pointer"></span>
        </td>
        <td>
          <span
            onClick={deleteItem}
            className="ion-android-delete pointer"
          ></span>
        </td>
      </tr>    
  );
};
