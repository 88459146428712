import React, { useState, useEffect } from 'react';
import { UserItem } from './UserItem';
import userService from '../../services/users.services';
import swal from 'sweetalert';
import { Link, useHistory } from 'react-router-dom';

export const UserList = () => {
  const [users, setUsers] = useState([]);
  const history = useHistory();

  // receives as a parameter a function that will be executed every 
  // time our component is rendered  
  useEffect(() => {
    getData();
  }, []);

  // the function "getData" is setting the data
  const getData = () => {
    // call the service and use a method "getAll" to return all the users
    userService
      .getAll()
      .then((users) => {        
        setUsers(users);
      })
      .catch((err) => {
        swal('Ha ocurrido un error', 'Intente nuevamente más tarde', 'error');
      });
  };

  const handleDelete = (id) => {
    
    const confirmed = window.confirm('¿Confirma eliminación?');
    if(!confirmed) {
      return;
    }

    userService
      .delete(id)
      .then((res) => {
        setUsers(
          users.map((el) => (el.id === id ? { 
            id: id, 
            firstName: null,
            lastName: null,
            email: null 
          } : el))
        );
      })
      .catch((err) => {
        swal('Ha ocurrido un error', 'Intente nuevamente más tarde', 'error');
      });
  };

  const handleEdit = (id) => {
    history.push(`/editProfile/${id}`);       

   
  };

  return (
    <div className="account-content pledges-received account-table">
      <h3 className="account-title">Administrar Usuarios</h3>
      <div className="account-main">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Email</th>
              <th>Editar</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((el) => (
                <UserItem
                  key={el.id}
                  id={el.id}
                  firstName={el.firstName}
                  lastName={el.lastName}
                  email={el.email}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />               
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
