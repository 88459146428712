import React, { useState, useEffect } from 'react';
import { participantsService } from '../../services/participants.services';
import { newsService } from '../../services/news.services';
import { ParticipantItem } from './ParticipantItem';
import swal from 'sweetalert';

export const ParticipantsList = () => {
  let [participants, setParticipants] = useState([]);

  useEffect(() => {
    participantsService
      .getReduced()
      .then((res) => {
        setParticipants(res);
      })
      .catch((err) => {
        swal('Ha ocurrido un error', 'Intente nuevamente más tarde', 'error');
      });
  }, []);

  const handleDelete = (id) => {
       
    const confirmed = window.confirm('¿Confirma eliminación?');
    if(!confirmed) {
      return;
    }
    
    participantsService
      .delete(id)
      .then((res) => {
        setParticipants(
          participants.map((el) => (el.id === id ? { id: id, name: null } : el))
        );
      })
      .catch((err) => {
        swal('Ha ocurrido un error', 'Intente nuevamente más tarde', 'error');
      });
  };

  

  return (
    <div className="account-content pledges-received account-table">
      <h3 className="account-title">Administrar Participantes ONG</h3>
      <div className="account-main">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Editar</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {participants &&
              participants.map((el) => (
                <ParticipantItem
                  key={el.id}
                  id={el.id}
                  name={el.name}
                  handleDelete={handleDelete}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
