import React, { Component } from 'react';
import API from '../../services/config.services';

//Method to sconvert phone number for render like a phone number
const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
  if (match) {
    return '+54 9 ' + match[1] + ' ' + match[2] + ' ' + match[3];
  }
  return phoneNumberString;
};

export class ContactWidget extends Component {
  state = {
    dataOng: []
  };

  async componentDidMount() {
    await this.fetchInfo();
  }

  //Use other method to obtain ong data from API
  fetchInfo = async () => {
    API.get('/ongs/data')
      .then((res) => {
        console.log(res.data);
        this.setState({ dataOng: res.data });
      })
      .catch((error) => {
        // handle the error
        console.error(error);
      });
  };

  render() {
    return (
      <div className="col-lg-4">
        <div className="contact-info">
          <p>Información de contacto</p>
          <ul>
            <li>
              <h5>{this.state.dataOng.name}</h5>
            </li>
            <li>
              <i className="fa fa-map-marker" aria-hidden="true"></i>
              {this.state.dataOng.address}
            </li>
            <li>
              <a href={formatPhoneNumber(this.state.dataOng.phone)}>
                <i className="fa fa-phone" aria-hidden="true"></i>
                {formatPhoneNumber(this.state.dataOng.phone)}
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
