import React from 'react';

export const FooterLegals = () => (
  <div className="container d-flex justify-content-center">
    <p className="copyright">
      &copy;{new Date().getFullYear()} Fortalecer Sin Fronteras | Todos los
      derechos reservados.
    </p>
  </div>
);
